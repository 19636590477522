import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que es reconeix pel color cridaner blau verdós del capell que amb l’edat passa a un verd grisenc. Aquest de fins a 8 cm de diàmetre inicialment és convex per passar a pla i presenta una gruixada capa mucilaginosa sobretot en temps humit. De jove presenta unes esquames blanquinoses. Les làmines tenen moltes lamel·les, gruixudes, adnades, de color blanquinós que amb l’esporada es tenyeixen de marró violaci amb l’aresta blanquinosa. El peu del mateix color que el capell, és cilíndric, viscós i llis per sobre de l’anell i amb esquames cotonoses per la part inferior. L’anell, situat a la part superior, és membranós, viscós i blanquinós però tenyit per la part superior per les espores. Aquestes són violàcies en massa, elipsoïdals, llises, de 7-9 x 4-5 micres. L’olor d’aquest bolet recorda al rave i si el tastem és dolç.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      